import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'custodyArrangementRadio',
    'overnightRangeFieldContainer',
    'nonPrimaryCustodyPartyPossessiveNameRelativeToOtherCaseBasicInfoParties',
    'numChildrenInput',
    'zeroChildrenInfoLabel',
    'custodyArrangementRadiosContainer',
    'splitCustodyArrangementRadioContainer',
    'neitherPartyRepresented',
    'neitherPartyRepresentedLabel',
    'partyRepresented',
    'budgetHeaderSection'
  ];

  connect() {
    this.toggleOvernightRangeFieldContainer();
    this.changeNonPrimaryCustodyParty();
    this.toggleNumberOfChildrenFields();
  }

  setNeitherPartyRepresented() {
    const neitherPartyRepresented = this.neitherPartyRepresentedTarget;
    const neitherPartyRepresentedLabel = this.neitherPartyRepresentedLabelTarget;
    const budgetHeaderSection =
      document.querySelector('[id=\'budget-header-section\']');
    const financialAffidavitPdfButton =
      document.querySelector('[id=\'financial-affidavit-pdf-button\']');

    if (neitherPartyRepresented.checked) {
      neitherPartyRepresented.value = true;
      neitherPartyRepresentedLabel.value = true;
      budgetHeaderSection.classList.add('hidden');
      financialAffidavitPdfButton.classList.add('hidden');
      const representedParty =
        this.partyRepresentedTargets.find(pr => pr.checked);
      if (representedParty) {
        representedParty.checked = false;
      }
    }
  }

  unsetNeitherPartyRepresented() {
    const representedParty =
      this.partyRepresentedTargets.find(pr => pr.checked);
    const neitherPartyRepresented = this.neitherPartyRepresentedTarget;
    const neitherPartyRepresentedLabel = this.neitherPartyRepresentedLabelTarget;
    const budgetHeaderSection =
      document.querySelector('[id=\'budget-header-section\']');
    const financialAffidavitPdfButton =
      document.querySelector('[id=\'financial-affidavit-pdf-button\']');
    if (representedParty) {

      budgetHeaderSection.classList.remove('hidden');
      financialAffidavitPdfButton.classList.remove('hidden');
      neitherPartyRepresented.checked = false;
      neitherPartyRepresented.value = false;
      neitherPartyRepresentedLabel.value = false;
    }
  }

  changeNonPrimaryCustodyParty() {
    const custodyArrangementRadio =
      this.custodyArrangementRadioTargets.find(car => car.checked);
    let otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml;

    if (custodyArrangementRadio === undefined) {
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml = '';
    } else {
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml =
        custodyArrangementRadio.dataset.otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml;
    }

    if (otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml === undefined) {
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml = '';
    }

    this.nonPrimaryCustodyPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesTarget.innerHTML =
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml;

    this.dispatch('non-primary-custody-party-changed');
  }

  toggleOvernightRangeFieldContainer() {
    const custodyArrangementRadio =
      this.custodyArrangementRadioTargets.find(car => car.checked);

    if (custodyArrangementRadio === undefined ||
      custodyArrangementRadio.value === 'shared') {
      this.overnightRangeFieldContainerTarget.style.display = 'none';
    } else if (custodyArrangementRadio.value == 'split') {
      this.overnightRangeFieldContainerTarget.style.display = 'none';
    } else {
      this.overnightRangeFieldContainerTarget.style.display = 'block';
    }
  }

  toggleNumberOfChildrenFields() {
    const zeroChildren = this.numChildrenInputTarget.value === '0';
    this.zeroChildrenInfoLabelTarget.hidden = !zeroChildren;
    this.custodyArrangementRadiosContainerTarget.hidden = zeroChildren;
    this.overnightRangeFieldContainerTarget.hidden = zeroChildren;

    if (this.hasSplitCustodyArrangementRadioContainerTarget) {
      const multipleChildren = (this.numChildrenInputTarget.value !== '1') ? '' : 'none';
      this.splitCustodyArrangementRadioContainerTarget.style.display = multipleChildren;
      if (multipleChildren === 'none') {
        document.getElementById('case_basic_info_custody_arrangement_key_or_primary_custody_party_id_split').checked = false
      } else {
        document.getElementById('case_basic_info_custody_arrangement_key_or_primary_custody_party_id_split').checked = true
      }
    }
  }
}
